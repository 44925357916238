import React from "react";
import { css } from "@emotion/react";
import { Link, graphql } from "gatsby";
import { useWindowSize } from "../hooks/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const githubIcon = <FontAwesomeIcon icon={faGithub} />;
const linkIcon = <FontAwesomeIcon icon={faLink} />;

const BREAKWIDTH = 1200;

const pageHeader = css`
  color: "#232129";
  font-family: -apple-system, "Roboto", sans-serif, serif;
  font-size: 24px;
  margin: 12px 8px;
`;

const container = css`
  display: flex;
  flex-flow: column;
  margin: 16px 24px 0;
`;

const titleStyle = css`
  color: darkslategray;
  font-size: 18px;
  font-family: -apple-system, "Roboto", sans-serif, serif;
  margin: 4px 0;
`;

const dateStyle = css`
  color: slategray;
  font-size: 14px;
  font-weight: lighter;
  font-family: -apple-system, "Roboto", sans-serif, serif;
  margin: 4px;
`;

const contentStyle = css`
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: normal;
  overflow: hidden;
`;

const link = css`
  color: darkslategray;
  align-self: end;
  font-weight: normal;
  // // padding-bottom: 4px;
  // margin-right: 40px;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const ProjectPreviewTemplate = ({ props }) => {
  const windowSize = useWindowSize();
  return (
    <div css={container}>
      <h4 css={titleStyle}>{props.frontmatter.title}</h4>
      <p css={dateStyle}>Started in {props.frontmatter.date}</p>
      <div
        css={css`
          display: flex;
          flex-flow: row;

          ${windowSize.width < BREAKWIDTH
            ? `justify-content: space-between;`
            : `
                & a:nth-of-type(2n) {
                  margin-left: 48px;
                }`}
        `}
      >
        {props.frontmatter.link && (
          <a
            css={link}
            href={props.frontmatter.link}
            target="_blank"
            rel="noreferrer"
          >
            {linkIcon} {props.frontmatter.link}
          </a>
        )}
        {props.frontmatter.github && (
          <a
            css={link}
            href={props.frontmatter.github}
            target="_blank"
            rel="noreferrer"
          >
            {githubIcon} GitHub
          </a>
        )}
      </div>

      <div>
        <p
          css={contentStyle}
          dangerouslySetInnerHTML={{ __html: props.excerpt }}
        ></p>

        <Link css={link} to={props.frontmatter.slug}>
          More about {props.frontmatter.title}
        </Link>
      </div>
      <hr
        css={css`
          margin-top: 16px;
          width: 100%;
        `}
      ></hr>
    </div>
  );
};

const ProjectsPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  return (
    <main>
      <h3 css={pageHeader}>My Projects</h3>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
          grid-auto-rows: minmax(100px, auto);
          gap: 20px;
        `}
      >
        {allMarkdownRemark.nodes.map((project) => {
          return (
            <ProjectPreviewTemplate
              id={project.id}
              key={project.id}
              props={project}
            ></ProjectPreviewTemplate>
          );
        })}
      </div>
    </main>
  );
};

export default ProjectsPage;

export const Projects = graphql`
  query Projects {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/markdown/projects/*.md" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY")
          slug
          link
          github
        }
        excerpt(format: HTML, pruneLength: 80, truncate: false)
        html
        id
      }
    }
  }
`;
